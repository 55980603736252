import React from 'react';
import { Link } from 'react-router-dom';
import { History, Moon, Plus, Smartphone } from 'lucide-react';
import Footer from 'components/application/Footer';
import { Heading, Text, ContentLayout } from 'components/common/ComponentLibrary';
import AuthenticationScreen from 'components/application/Authentication';
import CounterDemo from 'components/counters/CounterDemo';
import TallyTabbyLogoAnimated from 'components/application/TallyTabbyLogoAnimated';
import TallyTabbyLogo from 'components/common/TallyTabbyLogo';
import { getUrlHome } from 'utilities/urls.utils';

const features = [
  {
    title: 'Install on Your Phone',
    description: 'Add to your home screen and use it like a native app with our Progressive Web App.',
    icon: Smartphone,
  },
  {
    title: 'Unlimited Counters',
    description: 'Create as many counters as you need. No artificial limits, no premium tier required.',
    icon: Plus,
  },
  {
    title: 'History Tracking',
    description: 'View your counting history and track your progress over time with detailed insights.',
    icon: History,
  },
  {
    title: 'Light & Dark Themes',
    description: 'Easy on the eyes with automatic theme switching based on your system preferences.',
    icon: Moon,
  },
];

const LandingPageScreen = () => (
  <ContentLayout>
    <ContentLayout.Top>
      <div className='flex items-center justify-center gap-4 py-2 md:py-4'>
        <Link to={{ pathname: getUrlHome() }}>
          <div className='flex items-center gap-4'>
            <TallyTabbyLogo className='w-10' />
            <div>
              <Text fontWeight='bold' size='xxl'>
                Tally Tabby
              </Text>
            </div>
          </div>
        </Link>
      </div>
    </ContentLayout.Top>
    <ContentLayout.Content>
      <div className='flex m-auto flex-col md:flex-row items-center justify-between max-w-screen-xl md:mt-20 gap-10 py-10 md:py-20 px-4 md:px-0'>
        <section className='flex flex-col md:w-2/3 md:items-start items-center'>
          <h1 className='text-3xl md:text-6xl text-center md:text-left font-bold tracking-tight text-[#49392C] dark:text-white sm:text-6xl'>
            Count anything, <br />
            <span className='text-[#6F8B6B]'>track everything</span>
          </h1>
          <p className='text-xl md:text-2xl text-center md:text-left text-muted-foreground mt-6 md:mt-10'>
            The simplest way to keep track of anything in your life. From habits to inventory, TallyTabby makes counting
            effortless.
          </p>

          <div className='mt-6 md:mt-10 w-full'>
            <CounterDemo />
          </div>
        </section>
        <section className='bg-neutral-100 dark:bg-neutral-800 rounded-2xl p-5 md:p-7 w-full md:w-auto'>
          <Heading level={2} size='xl' spacing='l'>
            Get started
          </Heading>
          <AuthenticationScreen />
        </section>
      </div>

      {/* Features Section */}
      <section className='container py-20 md:py-40 m-auto px-4 md:px-0'>
        <div className='mx-auto max-w-2xl text-center'>
          <h2 className='text-2xl md:text-3xl font-bold tracking-tight text-[#49392C] dark:text-white sm:text-4xl'>
            Everything you need to start counting
          </h2>
          <p className='mt-4 text-base md:text-lg text-muted-foreground'>
            Simple yet powerful features that make tracking anything a breeze
          </p>
        </div>

        <div className='mt-10 md:mt-16 grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2'>
          {features.map((feature, i) => (
            <div
              key={feature.title}
              className='relative rounded-2xl border bg-white/50 p-6 md:p-8 shadow-lg backdrop-blur dark:border-zinc-800 dark:bg-zinc-900/50'
            >
              <div className='mb-4 inline-flex rounded-lg bg-[#6F8B6B]/10 p-3 text-[#6F8B6B]'>
                <feature.icon className='h-6 w-6' />
              </div>
              <h3 className='mb-2 text-lg font-medium text-[#49392C] dark:text-white'>{feature.title}</h3>
              <p className='text-muted-foreground'>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>
      <div className='m-auto w-full flex justify-center py-10 md:py-20'>
        <TallyTabbyLogoAnimated />
      </div>
    </ContentLayout.Content>
    <ContentLayout.Footer>
      <Footer />
    </ContentLayout.Footer>
  </ContentLayout>
);

export default LandingPageScreen;
